<template>
  <main className="py-5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
    <PageHeader title="Nieuwe gebruiker" />

    <div class="mt-10 sm:mt-0">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              General information
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              Here you can provide the information needed for the item.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <UserForm :user="data" v-if="data" @submitted="createUser" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from "vue";
import UserService from "../../../services/admin/users/service";
import UserForm from "../../../components/admin/users/UserForm.vue";

export default {
  name: "UserNew",
  components: {
    UserForm,
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);

    data.value = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      Roles: [],
      BusinessUnits: []
    }

    return {
      data,
      loading,
      error,
    };
  },
  methods: {
    async myMethod() {
      const { data } = await UserService.getUser(this.$route.params.id);
      this.data = data;
    },
    createUser({user, roles, bus}) {
      UserService.create({user: user, roles: roles, business_units: bus}).then((data) => {
        console.log(data.id);

        this.$router.push(`/users/${data.id}`);
      });
    },
    async destroy() {
      if(confirm('are you sure?')) {
        UserService.delete(this.data.id);
        this.$router.push("/users");
      }
    },
  },
};
</script>